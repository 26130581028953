<style scoped>

</style>

<template>
    <div>
        <div>
            <CAlert color="secondary">
                <small>
                    As notificações de aos colaboradores são disparados em forma de "push", a cada pedido gerado,
                    as notificações que se encontrarem na regra são disparados para o emitente ou para todos os usuários
                    de mesmo tipo ao dele, podendo ser utilizado como uma forma de incentivo à venda interna.
                    <br>
                    Obs: Recurso disponível apenas para o módulo de callcenter.
                </small>
            </CAlert>
        </div>
        <div>
            <crud-base
                :onDelete="() => {}"
                :onUpdate="() => {}"
                :onStore="() => {}"
                :url="url"
                :columns="columns"
                :formInputs="formInputs"
            />
        </div>
    </div>
</template>

<script>
  import CrudBase from "../../../../components/crud/CrudBase";
  import { get } from '../../../../helpers/apiRequest';

  export default {
    name: "NotificacoesColaboradores",
    components: {
      CrudBase
    },
    data() {
      return {
        url: '/admin/notificacoes/colaboradores',
        columns: [
          {label: 'ID', field: 'id', sortable: false},
          {label: 'Título', field: 'titulo', sortable: false},
          {label: 'Descrição', field: 'descricao', sortable: false},
          {label: 'Ativo', field: 'ativo', sortable: false, representedAs: ({ativo}) => ativo ? `Sim` : 'Não'},
        ],
        formInputs: {
          titulo: {
            type: 'string',
            ui: {
              label: 'Título',
            },
            rules: {
              required: {
                value: true,
                errMsg: "Campo de preenchimento obrigatório"
              },
            }
          },
          descricao: {
            type: 'string',
            ui: {
              widget: "textarea",
              label: 'Descrição',
              description: "Pode utilizar variáveis como :auth_colaborador_nome para exibir o nome do usuário emitente, :pedido_valortotal_formatado para exibir o valor do pedido formatado com R$",
            },
            rules: {
              "required": {
                "value": true,
                "errMsg": "Campo de preenchimento obrigatório"
              },
            }
          },
          ativo: {
            type: 'boolean',
            default: 1,
            ui: {
              columns: 6,
              label: 'Ativo',
              "widgetConfig": {
                "enumSource": [
                  {
                    "label": "Sim",
                    "value": 1
                  },
                  {
                    "label": "Não",
                    "value": 0
                  }
                ]
              },
            }
          },
          notifica_somente_emitente: {
            type: 'boolean',
            default: 1,
            ui: {
              columns: 6,
              label: 'Notifica somente o emitente',
              widgetConfig: {
                enumSource: [
                  {
                    "label": "Sim",
                    "value": 1
                  },
                  {
                    "label": "Não",
                    "value": 0
                  }
                ]
              },
            }
          },
          tempo_para_fechamento: {
            type: 'number',
            ui: {
              label: 'Tempo para fechamento da notificação',
              description: "Deixe em branco para não fechar automático, ou utilize tempo em milisegundos, onde 1000 é 1 segundo",
            },
          },
          tipo_notificacao: {
            type: 'boolean',
            default: "DEFAULT",
            ui: {
              label: 'Tipo de notificação',
              widgetConfig: {
                enumSource: [
                  {
                    "label": "Padrão ( Apenas Texto )",
                    "value": "DEFAULT"
                  },
                  {
                    "label": "Imagem",
                    "value": "IMAGEM"
                  },
                ]
              },
            },
          },
          tipo_id: {
            type: 'string',
            ui: {
              label: 'URL da Imagem',
              hidden: "dx: {{$root.tipo_notificacao}} != 'IMAGEM'"
            },
          },

          _gatilhos: {
            type: "HTML",
            value: "<hr><h3>Gatilhos</h3>",
          },

          /// GATILHOS
          tipo_evento: {
            type: 'boolean',
            default: 'PEDIDO_ENVIADO',
            ui: {
              label: 'Tipo de evento',
              columns: 6,
              widgetConfig: {
                enumSource: [
                  {
                    "label": "Pedido enviado",
                    "value": 'PEDIDO_ENVIADO'
                  },
                ]
              },
            }
          },
          valor_minimo: {
            type: 'number',
            ui: {
              columns: 6,
              label: 'Valor mínimo do pedido',
              description: "Pedidos de valor maior ou igual a este para ativar o gatilho",
            },
          },
          origem_pedido: {
            type: 'boolean',
            default: 'CALLCENTER',
            ui: {
              columns: 6,
              label: 'Origem do pedido',
              widgetConfig: {
                enumSource: [
                  {
                    "label": "Callcenter",
                    "value": "CALLCENTER"
                  },
                ]
              },
            },
          },
          tipo_colaborador_id: {
            type: 'string',
            ui: {
              columns: 6,
              widget: "radio",
              label: 'Tipo de colaborador que enviou o pedido',
              widgetConfig: {
                "itemDataKey": "selectedItem",
                enumSource: [
                  {
                    "value": "1",
                    "label": "ncform",
                    "desc": "ncform is a very nice configuration generation way to develop forms"
                  },
                  {
                    "value": "2",
                    "label": "daniel",
                    "desc": "Daniel is the author of ncform"
                  }
                ]
              },
            },
          }
        }
      }
    },
    methods: {
      loadTiposColaboradores() {
        get('/admin/tipos-colaborador')
          .then((json) => {
            this.formInputs.tipo_colaborador_id.ui.widgetConfig.enumSource = json.reduce((acc, item) => {
              item.label = item.desc;
              item.value = item.id;
              acc.push(item);
              return acc;
            }, []);
          })
          .catch((err) => {});
      }
    },
    beforeMount() {
      this.loadTiposColaboradores();
    },
  }
</script>