<style scoped>

</style>

<template>
    <div>
        <div>
            <CAlert color="secondary">
                <small>
                    As notificações de boletos são emails de lembretes, enviados antes ou após o vencimento. Configure os melhores
                    dias para que o cliente receba da forma mais eficaz. Para que as notificações funcionem corretamente, é
                    necessário ativar e configurar o horário de disparo em:
                    <br>
                    <strong>"Configurações / Configurações -> GERAL | Envia notificação de vencimento e PDF de boletos por email?"</strong>.
                </small>
            </CAlert>
        </div>
        <div>
            <crud-base
                :onDelete="() => {}"
                :onUpdate="() => {}"
                :onStore="() => {}"
                :url="url"
                :columns="columns"
                :formInputs="formInputs"
            />
        </div>
    </div>
</template>

<script>
  import CrudBase from "../../../../components/crud/CrudBase";

  export default {
    name: "NotificacoesBoletos",
    components: {
      CrudBase
    },
    data() {
      return {
        url: '/admin/notificacoes/boletos',
        columns: [
          { label: 'ID', field: 'id', sortable: false },
          { label: 'Assunto Email', field: 'assunto_email', sortable: false },
          { label: 'Dias vencimento', field: 'dias_vencimento', sortable: false },
          { label: 'Ativo', field: 'ativo', sortable: false, representedAs: ({ativo}) => ativo ? `Sim` : 'Não' },
        ],
        formInputs: {
          assunto_email: {
            type: 'string',
            ui: {
              label: 'Assunto Email',
            },
            rules: {
              "required": {
                "value": true,
                "errMsg": "Campo de preenchimento obrigatório"
              },
            }
          },
          dias_vencimento: {
            type: 'number',
            ui: {
              label: 'Dias para vencimento do boleto',
              description: "Utilize positivo para dias anterior ao vencimento e negativo para após vencimento",
            },
            rules: {
              "required": {
                "value": true,
                "errMsg": "Campo de preenchimento obrigatório"
              },
            }
          },
          ativo: {
            type: 'boolean',
            default: 1,
            ui: {
              label: 'Ativo',
              "widgetConfig": {
                "enumSource": [
                  {
                    "label": "Sim",
                    "value": 1
                  },
                  {
                    "label": "Não",
                    "value": 0
                  }
                ]
              },
            }
          },
        }
      }
    },
  }
</script>