<style scoped>

</style>

<template>
    <CTabs variant="tabs" class="nav-tabs-boxed">
        <CTab title="Emails de boletos">
            <NotificacoesBoletos />
        </CTab>
        <CTab title="Vendas Colaboradores">
            <NotificacoesColaboradores />
        </CTab>
    </CTabs>
</template>

<script>

  import NotificacoesBoletos from './components/Boletos/NotificacoesBoletos'
  import NotificacoesColaboradores from './components/Colaboradores/NotificacoesColaboradores'

  export default {
    name: "Notificacoes",
    components: {
      NotificacoesBoletos,
      NotificacoesColaboradores
    }
  }
</script>